import React, {useEffect} from "react"
import { Link, graphql } from "gatsby"
import styled from 'styled-components';
import {log} from './../utilities/measure.js';
import Seo from './../components/Seo/index';
import AnimatedPageWrapper from '../components/AnimatedPageWrapper/index';

const AboutPage = ({location, data}) => {
  log(`AboutPage data`, data);
  log(`AboutPage location`,location);
  return  <AnimatedPageWrapper id="about-page" key="about-page">
    <AboutWrapper key="about">
            <Seo
              title={"About Medication Math"}
              description={"about medication math"}
              pathname={location.pathname}
            />
            <h2 className="title">About Me</h2>
            <p key={'exp'}>{data.site.siteMetadata.title} is an experimental print and play card game created by me, Victoria.</p>
            <p key={'stims'}>I own a company, Stimsims Pty Ltd, that makes text based math simulations. 
            You can visit the website <a href="https://www.stimsims.com" target="_blank">here</a></p>
    </AboutWrapper>
  </AnimatedPageWrapper>
}
export default AboutPage;

const AboutWrapper = styled.section`
    padding: 20px 10px 10px 10px;
    max-width: ${props => props.theme.widthL};
    margin: auto;
    .title{
        text-align: center;
    }
    .statement{

    }
`

export const query = graphql`
  query AboutPage{
    site {
      ...SiteInformation
    }
  }
`